<template>
  <div class="pb-1">
    <h3>
      {{ $t("category") }}
    </h3>

    <Filters :search="true" @search="onSearch" @addModal="showModal" />

    <b-card>
      <b-table
        :items="GET_PRODUCTS.data.categories"
        :fields="fields"
        :busy="isBusy"
        responsive="sm"
        hover
        striped
        show-empty
      >
        <template #empty>
          <h5 class="text-center py-2">{{ $t('no_data') }}</h5>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">
              <span v-if="$i18n.locale == 'uz'"> Yuklanmoqda... </span>
              <span v-else-if="$i18n.locale == 'ru'"> Загрузка... </span>
            </strong>
          </div>
        </template>

        <template #cell(image)="row">
          <div class="text-center">
            <img
              :src="row.item.image_adress"
              v-if="row.item.image"
              class="img-thumbnail"
              style="height: 100px"
            />

            <!-- placeholder image -->
            <img
              v-else
              src="https://via.placeholder.com/50"
              class="img-fluid rounded img-thumbnail"
            />
          </div>
        </template>

        <template #cell(name)="data">
          <span v-if="$i18n.locale === 'ru'">
            {{ data.item.russian_name }}
          </span>
          <span v-else>
            {{ data.item.name }}
          </span>
        </template>

        <template #cell(actions)="row">
          <div class="text-center d-flex justify-content-center">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="showModal(row.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="showDeleteModal(row.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <Pagination
      :users_count="GET_PRODUCTS.data.pagination[0].totalItems"
      @changePaginationValue="changePaginationValue"
    />

    <CategoryAddModal
      :isEdit="isEdit"
      :editData="editData"
      @restart="restart"
    />
    <AreYouSureModal @ok="deleteCategory" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Filters from "@/components/Filters.vue";
import Pagination from "@/components/Pagination.vue";
import CategoryAddModal from "./components/CategoryAddModal.vue";
import AreYouSureModal from "@/components/AreYouSureModal.vue";
import {
  BTable,
  BCard,
  BButton,
  BSpinner,
  BModal,
  BFormInput,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";

export default {
  components: {
    BTable,
    BCard,
    BButton,
    BSpinner,
    BModal,
    BFormInput,
    BFormSelect,
    BFormGroup,
    Filters,
    Pagination,
    AreYouSureModal,
    CategoryAddModal,
  },

  data() {
    return {
      isBusy: true,
      editData: {},
      deleteId: null,
      isEdit: false,
      pagination: {
        total: 4,
        current: 1,
        from: 1,
        next: 2,
        perpage: 10,
        previoust: 0,
        to: 10,
        totalItems: 38,
      },
      fields: [
        {
          key: "image",
          label: this.$t("image"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "name",
          label: this.$t("name"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "priority",
          label: this.$t("priority"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "totalProduct",
          label: this.$t("totalProduct"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: this.$t("actions"),
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },

  computed: {
    ...mapGetters("category", ["GET_PRODUCTS"]),
    ...mapState("category", ["products", "response"]),
  },

  async mounted() {
    await this.FETCH_CATEGORIES({ limit: 5 });
    this.$nextTick(() => {
      this.isBusy = false;
    });
  },

  methods: {
    ...mapActions("category", ["FETCH_CATEGORIES", "DELETE_CATEGORY"]),

    async restart() {
      this.isBusy = true;
      await this.FETCH_CATEGORIES();
      this.$nextTick(() => {
        this.isBusy = false;
      });
    },

    // search filter
    onSearch(search) {
      this.FETCH_CATEGORIES({ search });
    },

    // open modal
    showModal(item) {
      if (item) {
        this.editData = item;
        this.isEdit = true;
      } else {
        this.isEdit = false;
        this.editData = {};
      }
      this.$nextTick(() => {
        this.$bvModal.show("category-add-modal");
      });
    },

    // open delete modal
    showDeleteModal(item) {
      this.deleteId = item.id;
      this.$nextTick(() => {
        this.$bvModal.show("are-you-sure-modal");
      });
    },

    deleteCategory() {
      this.DELETE_CATEGORY(this.deleteId).then(() => {
        this.restart();
      });
    },

    // pagination
    changePaginationValue(value) {
      this.isBusy = true;
      this.FETCH_CATEGORIES({ page: value.page, limit: value.page_size }).then(
        () => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        }
      );
    },
  },
};
</script>
